import Bugsnag from "@bugsnag/js";
import Router from "next/router";
import { v4 as uuidv4 } from "uuid";
import { getAdData } from "./Analytics";
import { CONDO_ENUM } from "./constants";
import { setCookie } from "cookies-next";

import { generateApiUrl, GET_CONFIG, POST_CONFIG } from "./fetch";

const poll = (promise, delay) => {
  let loop = 1;
  return new Promise((resolve) => {
    (async function waitForPoll() {
      let result;
      if (loop > 8) {
        delay *= loop / 2;
      }
      try {
        result = await promise();
        resolve(result);
      } catch {
        loop += 1;
        setTimeout(waitForPoll, delay);
      }
    })();
  });
};

export async function pollLeadCreated() {
  const res = await isLeadCreated();
  if (res.status === 200) {
    return res;
  } else {
    await timeout(1000);
    return pollLeadCreated();
  }
}

export async function isLeadCreated() {
  const _leadUUID = leadUUID();
  const config = GET_CONFIG({ leadUUID: _leadUUID });
  return fetch(generateApiUrl("funnel/is_lead_created"), config);
}

export async function lightningCheck() {
  const _leadUUID = leadUUID();
  const config = GET_CONFIG({ leadUUID: _leadUUID });
  return fetch(generateApiUrl("funnel/check_lightning3"), config);
}

async function fetchMatches() {
  const { count, leads } = getCompletedLeads();
  if (count === 0) {
    return null;
  }
  const _leadUUID = leads[0];
  const config = GET_CONFIG({});
  return fetch(generateApiUrl(`funnel/get_matches`), config);
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function pollMatches() {
  const res = await fetchMatches();
  const json = res ? await res.json() : { retry: true };
  if (json.retry) {
    setTimeout(() => {
      return pollMatches();
    }, 5000);
  } else {
    return json;
  }
}

export async function pollLightningCheck() {
  const res = await lightningCheck();
  if (res.status === 200) {
    return res;
  } else {
    await timeout(1000);
    return pollLightningCheck();
  }
}

export function saveContact(key, value) {
  const contact = getContactJSON();
  contact[key] = value;
  window.localStorage.setItem("contact", JSON.stringify(contact));
}

export function getContact(key) {
  const contact = getContactJSON();

  return contact && contact[key];
}

export function saveValue(key, value, leadType) {
  if (!process.browser) {
    return;
  }
  const lead = getLeadJSON();

  const _leadType = Router.query.leadType || leadType;
  lead[_leadType][key] = value;
  window.localStorage.setItem("lead", JSON.stringify(lead));
  setCookie("lead", JSON.stringify(lead));
}

export function getValue(key, leadTypeArg = null) {
  if (process.browser) {
    const lead = getLeadJSON();
    const leadType = leadTypeArg ? leadTypeArg : Router.query.leadType;

    if (lead[leadType]) {
      return lead[leadType][key];
    }
  }
}

export function setSessionUUID({
  _sessionUUID,
  server,
  cookies,
  query,
  res,
  force,
}) {
  if (server) {
    const sessionUUID = cookies.sessionUUID;
    if (!sessionUUID) {
      const newUUID = query.session_uuid
        ? query.session_uuid
        : _sessionUUID
        ? _sessionUUID
        : uuidv4();
      res.setHeader("set-cookie", [`sessionUUID=${newUUID}`]);
      return newUUID;
    }
    return sessionUUID;
  } else {
    const sessionUUID = window.localStorage.getItem("sessionUUID");
    if (!sessionUUID || force) {
      const newUUID = _sessionUUID
        ? _sessionUUID
        : Router.query.session_uuid
        ? Router.query.session_uuid
        : uuidv4();
      window.localStorage.setItem("sessionUUID", newUUID);
      return newUUID;
    }
    return sessionUUID;
  }
}

export function resetLead() {
  window.localStorage.removeItem("lead");
  window.localStorage.removeItem("leadUUID");
  window.localStorage.removeItem("leadType");
  window.localStorage.removeItem("completedSteps");
}

export function resetContact() {
  window.localStorage.removeItem("contact");
}

export function resetAddressInfo({ leadType } = {}) {
  const lead = getLeadJSON();

  const _leadType =
    window.localStorage.getItem("leadType") ||
    Router.query.leadType ||
    leadType;

  const addressKeys = [
    "address",
    "city",
    "country",
    "state",
    "streetName",
    "streetNumber",
    "zip",
  ];
  addressKeys.forEach((key) => {
    delete lead[_leadType][key];
  });
  window.localStorage.setItem("lead", JSON.stringify(lead));
}

export const sendGeocode = ({ google_response, geocode_input, lead_type }) => {
  const geocode_request_uuid = uuidv4();
  window.localStorage.setItem("geocode_request_uuid", geocode_request_uuid);
  const data = {
    google_response,
    lead_type: lead_type,
    geocode_input: geocode_input,
    geocode_request_uuid: geocode_request_uuid,
  };
  return fetch(generateApiUrl("/funnel/record_geocode"), POST_CONFIG({ data }));
};

export function leadSubmissionData({
  otherLead,
  leadType,
  mode,
  consent,
  uuid,
  additionalLeadParams = {},
}) {
  const _leadType = leadType || window.localStorage.getItem("leadType");
  const _leadUUID = uuid ? uuid : otherLead ? uuidv4() : leadUUID();
  const adData = getAdData();
  adData.referrer = window.localStorage.getItem("referrer");
  const phone = getContact("phone");

  const data = {
    mode,
    ad_data: adData,
    user: {
      first_name: getContact("firstName"),
      last_name: getContact("lastName"),
      email: getContact("email"),
      phonenumber:
        phone?.length === 10 ? getContact("dialCode") + phone : phone,
    },
    lead: {
      geocode_request_uuid:
        Router.query.leadType === "both"
          ? otherLead
            ? window.localStorage.getItem("geocode_request_uuid_buyer")
            : window.localStorage.getItem("geocode_request_uuid_seller")
          : window.localStorage.getItem("geocode_request_uuid"),
      lead_type: _leadType,
      uuid: _leadUUID,
      country_code: getContact("dialCode"),
      language_preference: Router.locale === "en-US" ? "en" : Router.locale,
      ...additionalLeadParams,
    },
    consent,
  };

  const leadData = getLeadData(_leadUUID, leadType, otherLead);
  const city = getValue("city", leadType);
  const state = getValue("state", leadType);

  if (
    (!city || !state || city === "undefined" || state === "undefined") &&
    !additionalLeadParams?.twofa_payload
  ) {
    Bugsnag.notify("Incomplete lead data", function (event) {
      event.addMetadata("submission_data", data);
    });
    console.log("incomplete data");
    console.log(data);
    return { ok: false };
  }

  data.lead = { ...data.lead, ...leadData };
  data.signup02_data = {
    ...leadData,
  };
  data.rocketMortgage = getValue("rocketMortgage");

  const expid = window.localStorage.getItem("expid");

  if (expid) {
    data.signup02_data.experiment_id = expid;
  }

  data.page = window.location.href.replace(window.location.origin, "");
  return data;
}

export function leadSubmission({
  otherLead,
  leadType,
  mode,
  consent,
  uuid,
  previousLeadUUID,
  additionalLeadParams = {},
} = {}) {
  const _leadType = leadType || window.localStorage.getItem("leadType");
  const _leadUUID = uuid ? uuid : otherLead ? uuidv4() : leadUUID();
  const adData = getAdData();
  adData.referrer = window.localStorage.getItem("referrer");
  const phone = getContact("phone");

  const data = {
    mode,
    ad_data: adData,
    user: {
      first_name: getContact("firstName"),
      last_name: getContact("lastName"),
      email: getContact("email"),
      phonenumber:
        phone?.length === 10 ? getContact("dialCode") + phone : phone,
    },
    lead: {
      geocode_request_uuid:
        Router.query.leadType === "both"
          ? otherLead
            ? window.localStorage.getItem("geocode_request_uuid_buyer")
            : window.localStorage.getItem("geocode_request_uuid_seller")
          : window.localStorage.getItem("geocode_request_uuid"),
      lead_type: _leadType,
      uuid: _leadUUID,
      country_code: getContact("dialCode"),
      language_preference: Router.locale === "en-US" ? "en" : Router.locale,
      ...additionalLeadParams,
    },
    consent,
  };

  const leadData = getLeadData(_leadUUID, leadType, otherLead);
  const city = getValue("city", leadType);
  const state = getValue("state", leadType);

  if (
    (!city || !state || city === "undefined" || state === "undefined") &&
    !additionalLeadParams?.twofa_payload
  ) {
    Bugsnag.notify("Incomplete lead data", function (event) {
      event.addMetadata("submission_data", data);
    });
    console.log("incomplete data");
    console.log(data);
    return { ok: false };
  }

  data.lead = { ...data.lead, ...leadData };
  data.signup02_data = {
    ...leadData,
  };
  data.rocketMortgage = getValue("rocketMortgage");

  const expid = window.localStorage.getItem("expid");

  if (expid) {
    data.signup02_data.experiment_id = expid;
  }

  data.page = window.location.href.replace(window.location.origin, "");

  const config = POST_CONFIG(
    otherLead ? { data, leadUUID: previousLeadUUID } : { data }
  );

  saveLeadUUID();

  if (!otherLead) {
    window.localStorage.setItem("lastLeadType", _leadType);
    if (_leadType === "seller") {
      window.localStorage.setItem("last-seller-uuid", _leadUUID);
    } else if (_leadType === "buyer") {
      window.localStorage.setItem("last-buyer-uuid", _leadUUID);
    }
  } else {
    window.localStorage.setItem("last-other-uuid", _leadUUID);
  }

  const url = generateApiUrl(
    otherLead ? "funnel/submit_other_lead" : "funnel/submit"
  );

  return fetch(url, config);
}

export function getLeadData(_leadUUID = null, leadTypeArg, otherLead) {
  const leadType = leadTypeArg || window.localStorage.getItem("leadType");
  const city = getValue("city", leadType);
  const state = getValue("state", leadType);
  if (leadType === "seller") {
    return {
      zip: getValue("zip", leadType),
      city: city,
      uuid: _leadUUID,
      state: state,
      country: getValue("country", leadType),
      type_home: getValue("property", leadType),
      street_name: getValue("streetName", leadType),
      street_number: getValue("streetNumber", leadType),
      home_price_max: getValue("priceMax", leadType),
      home_price_min: getValue("priceMin", leadType),
      lat_lng: getValue("latLng", leadType),
      text_message_consent: getContact("textMessageOK", leadType),
      unit_number:
        getValue("property", leadType) === CONDO_ENUM
          ? getValue("unitNumber", leadType)
          : null,
    };
  } else if (leadType === "both") {
    return {
      zip: getValue("zip", leadType),
      buyer_cities:
        getValue("buyer_city") &&
        getValue("buyer_state") &&
        `${getValue("buyer_city")}, ${getValue("buyer_state")}`,
      city: city,
      uuid: _leadUUID,
      state: state,
      country: getValue("country", leadType),
      type_home: getValue("property", leadType),
      street_name: getValue("streetName", leadType),
      street_number: getValue("streetNumber", leadType),
      home_price_max: getValue("priceMax", leadType),
      home_price_min: getValue("priceMin", leadType),
      lat_lng: getValue("latLng", leadType),
      text_message_consent: getContact("textMessageOK", leadType),
      unit_number:
        getValue("property", leadType) === CONDO_ENUM
          ? getValue("unitNumber", leadType)
          : null,
    };
  } else if (leadType === "buyer") {
    return {
      buyer_cities: city && state && `${city}, ${state}`,
      uuid: _leadUUID,
      country: getValue("country", leadType),
      time_frame: getValue("timeframe", leadType),
      mortgage_status: getValue("mortgage", leadType),
      home_price_max: getValue("priceMax", leadType),
      home_price_min: getValue("priceMin", leadType),
      lat_lng: getValue("latLng", leadType),
      looking_to_sell: getValue("looking_to_sell", leadType),
      text_message_consent: getContact("textMessageOK", leadType),
    };
  }

  return {};
}

export function leadUUID() {
  const expiryDateInMS = 7 * 24 * 60 * 60 * 1000; // one week currently in MS
  const now = new Date();
  const expiryKey = "lead-uuid-expiry-date";

  if (process.browser) {
    const params = new URL(document.location).searchParams;
    let leadUUID = params.get("uuid");

    const storedDate = localStorage.getItem(expiryKey);
    const expiryDate = new Date(storedDate);
    const newExpiryDate = new Date(now.getTime() + expiryDateInMS);

    if (leadUUID) {
      window.localStorage.setItem(expiryKey, newExpiryDate.toISOString());
      window.localStorage.setItem("leadUUID", leadUUID);
      return leadUUID;
    } else {
      if (storedDate && now < expiryDate) {
        // If our lead uuid is not expired
        leadUUID = window.localStorage.getItem("leadUUID");
      }
    }

    if (!leadUUID) {
      const newUUID = uuidv4();
      window.localStorage.setItem(
        "lead-uuid-expiry-date",
        newExpiryDate.toISOString()
      );
      window.localStorage.setItem("leadUUID", newUUID);
      return newUUID;
    }

    return leadUUID;
  }
}

function getLeadJSON() {
  if (process.browser) {
    let lead = window.localStorage.getItem("lead");

    if (lead) {
      lead = JSON.parse(lead);
    } else {
      lead = {
        buyer: {},
        seller: {},
        both: {},
      };
    }
    return lead;
  }
}

function getContactJSON() {
  if (process.browser) {
    let contact = window.localStorage.getItem("contact");
    if (contact) {
      contact = JSON.parse(contact);
    } else {
      contact = {};
    }

    return contact;
  }
}

export function getCompletedLeads() {
  const buyers = window.localStorage.getItem("completed_buyer");
  const sellers = window.localStorage.getItem("completed_seller");
  const both = window.localStorage.getItem("completed_both");
  let leads = [];
  let total = 0;

  if (buyers) {
    const parsed = JSON.parse(buyers);
    total += parsed.length;
    leads = [...parsed];
  }

  if (sellers) {
    const parsed = JSON.parse(sellers);
    total += parsed.length;
    leads = [...leads, ...parsed];
  }

  if (both) {
    const parsed = JSON.parse(both);
    total += parsed.length;
    leads = [...leads, ...parsed];
  }

  return { total, leads };
}

function saveLeadUUID() {
  const leadType =
    window.localStorage.getItem("leadType") || Router.query.leadType;
  const leadTypeKey = `completed_${leadType}`;
  const _leadUUID = leadUUID();

  let completedLeads = window.localStorage.getItem(leadTypeKey);

  if (completedLeads) {
    completedLeads = JSON.parse(completedLeads);
    completedLeads.push(_leadUUID);
  } else {
    completedLeads = [_leadUUID];
  }

  window.localStorage.setItem(leadTypeKey, JSON.stringify(completedLeads));
}
