import { createFunnelEvent, getAdData } from "./Analytics";
import { generateApiUrl, GET_CONFIG } from "./fetch";
import { leadUUID } from "./Lead";

const isProd = process.env.NODE_ENV === "production";

export const ASSET_BASE_URL = isProd ? "/compare-agents" : "";
export const CONDO_ENUM = "Condominium";

const dataToPass = ({ uuid }) => {
  const _leadUUID = uuid ? uuid : leadUUID();
  const sessionUUID = window.localStorage.getItem("sessionUUID");
  const referrer = window.localStorage.getItem("referrer");
  const adData = getAdData();
  const urlParams = new URLSearchParams(adData);
  urlParams.append("uuid", _leadUUID);
  urlParams.append("set_session_uuid", sessionUUID);
  urlParams.append("referrer", referrer);
  const expid = window.localStorage.getItem("expid") || "orginal";
  urlParams.append("show_rea_funnel_skin", "true");
  urlParams.append("force_resubmit", true);
  urlParams.append("force_experiment_group", expid);

  return urlParams.toString();
};

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL
  ? process.env.NEXT_PUBLIC_API_BASE_URL
  : isProd
  ? "https://rea.staging.referralexchange.com/api/"
  : "http://localhost:8000/api/";

export const POSTCONVERT_URL = ({ uuid }) => {
  if (process.browser) {
    const data = dataToPass({ uuid });
    const url = process.env.NEXT_PUBLIC_POSTCONVERT_URL
      ? process.env.NEXT_PUBLIC_POSTCONVERT_URL + `&${data}`
      : `/app?${data}&force_path=true&force_resubmit=true`;
    return url;
  }
};

const trackEvent = ({ event_type, data }) => {
  return createFunnelEvent({ details: data, eventType: event_type });
};

const fetchAgents = async ({ uuid }) => {
  const url = generateApiUrl(`funnel/get_matches?uuid=${uuid}`);
  const resp = await fetch(url, GET_CONFIG({ leadUUID: uuid }));
  if (resp.ok) {
    const json = await resp.json();
    return json;
  } else {
    return { agents: [], error: true };
  }
};

export const BOTH_BUYER_URL = async ({ experiment, uuid }) => {
  const data = dataToPass({ uuid });
  const agents = await fetchAgents({ uuid });
  if (agents.agents.length === 0) {
    const twofaMode = await fetchLightning({ tries: 1, uuid });

    if (twofaMode) {
      return process.env.NEXT_PUBLIC_BOTH_BUYER_URL
        ? process.env.NEXT_PUBLIC_BOTH_BUYER_URL +
            `${data}&twofa_mode=${twofaMode}`
        : `${ASSET_BASE_URL}/verify?${data}&twofa_mode=${twofaMode}`;
    }
  }

  return process.env.NEXT_PUBLIC_BOTH_MRC_URL
    ? process.env.NEXT_PUBLIC_BOTH_MRC_URL + `${data}`
    : `${ASSET_BASE_URL}/both/mrc?${data}&agentTaxonomy=realtor&created_lead=seller&step=verify&lead_type=buyer&stay=true&force_path=true`;
};

const fetchLightning = async ({ tries = 1, uuid }) => {
  const url = generateApiUrl(`funnel/check_lightning3`);
  const config = GET_CONFIG({ leadUUID: uuid });
  let error = false;
  const checkLightning3 = url.includes("check_lightning3");

  try {
    const resp = await fetch(url, config);
    const json = await resp.json();
    if (json.routing) {
      let routingKey = "routing";
      let routingValue = "lightning";

      if (checkLightning3) {
        routingKey = "route";
        routingValue = "MATCH";
      }
      if (json.routing[routingKey] !== routingValue) {
        const nonLightningReasons = checkLightning3
          ? json.routing
          : json.routing.raw_response[json.routing.lead_id].funnel_problems;
        const twofaMode = checkLightning3
          ? handleNonLightningReasons3(nonLightningReasons)
          : handleNonLightningReasons(nonLightningReasons);

        return twofaMode;
      }
      return false;
    } else {
      error = true;
    }
  } catch (e) {
    console.log(e);
    error = true;
  }

  if (error && tries < 60) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return fetchLightning({ tries: tries + 1, uuid });
  } else {
    console.log("60s_timeout");
    trackEvent({
      event_type: "check_lightning_60s_timeout",
    });
  }
};

const handleNonLightningReasons3 = (reasons) => {
  if (!reasons) {
    return null;
  }

  let twofaMode = "phone";

  if (!reasons.valid_name || reasons.profane_name) {
    twofaMode = "contact";
  }

  if (!reasons.valid_email || reasons.profane_email || reasons.missing_email) {
    twofaMode = "email";
  }

  return twofaMode;
};

const handleNonLightningReasons = (reasons) => {
  const nonLightningReasons = {
    dupe_email: {
      twofaMode: "email",
      rank: 0,
    },
    address: {
      twofaMode: "address",
      rank: 1,
    },
    email: {
      twofaMode: "email",
      rank: 0,
    },
    name: {
      twofaMode: "contact",
      rank: 2,
    },
  };
  let allReasons = reasons ? Object.keys(reasons) : [];
  let twofaMode = null;
  let currentRank = 0;
  for (let i = 0; i < allReasons.length; i++) {
    if (reasons[allReasons[i]]) {
      let currentReason = nonLightningReasons[allReasons[i]];
      if (currentReason && currentReason.rank > currentRank) {
        twofaMode = currentReason.twofaMode;
        currentRank = currentReason.rank;
      }
    }
    if (reasons["agent_email"] || reasons["agent_phone"]) {
      // Agent, ignore them
    } else {
      return twofaMode;
    }
  }
};
